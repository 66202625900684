import { useContext, useState } from "react";
import { DataContext } from "../../../DataWrapper/DataWrapper";
import _ from "lodash";
import getTftIconUrl from "../../../utils/TftIconUrl";
import { ObjectToArray } from "../../../utils/ObjectToArray";
import StatHighlight from "./StatHighlight";

const TftAugmentsStats = ({player, stats, site}) => {
  
  const {tftData} = useContext(DataContext);
  const setData = tftData[`set_${site.tftSet}`];
  const augmentsStats = setData.augments;;
  const [showAll, setShowAll] = useState(false);

  if(!stats) return;

  let unsorted = ObjectToArray(stats.augmentStats);
  let sorted = _.sortBy(unsorted, a => a.obj.games).reverse();
  sorted = showAll ? sorted : sorted.slice(0, 5);

  return <div className="card mb-2">
  <div className="card-header position-relative">
    Augments Stats
    <span className="show-all" onClick={() => {
      setShowAll(prev => !prev);
    }}>{showAll ? "Show 5" : "Show all"}</span>
  </div>
  <div className="card-body p-1">
    <ul className="list-group">
      {sorted.map(a => {
        let placeholder = "https://ddragon.leagueoflegends.com/cdn/" + site.leagueVersion + ".1/img/tft-augment/@@name@@";
        let imageUrl = placeholder.replace("@@name@@", augmentsStats.data[a.name]?.image?.full);
        let label = augmentsStats.data[a.name]?.name || a.name;

        let averagePosition = 0;
        let sumPositions = 0;
        for(let i = 1; i < 9; i++) {
          sumPositions += a.obj.positions[i] * i
        }
        if(stats.games > 0) averagePosition = (sumPositions / a.obj.games); 

        return <StatHighlight
          name={a.name}
          label={label}
          imageUrl={imageUrl}
          stat1Label={"Games"}
          stat2Label={"Top-4 %"}
          stat3Label={"Avg. Pos."}
          stat1={a.obj.games}
          stat2={(a.obj.wins / a.obj.games * 100).toFixed(1) + " %"}
          stat3={"#" + averagePosition.toFixed(2)}
          key={a.name}
        />
      })}
    </ul>
  </div>
</div>
}


export default TftAugmentsStats;