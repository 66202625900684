import A1Drake from "../../assets/images/a1drake.png";
import TftLogo from "../../assets/images/tft.png";
import ValorantLogo from "../../assets/images/valorant.png";
import LeagueLogo from "../../assets/images/league.png";

import { NavLink, Link } from "react-router-dom";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';


import "./Navbar.scss";
import { useContext } from "react";
import { UserContext } from "../User/User";

const CustomNavbar = () => {

  const { token, setToken, publicAccess, setPublicAccess } = useContext(UserContext)

  const logOut = () => {
    setToken(undefined)
    setPublicAccess(false)
  }

  return (
    <div className="page-navbar">
      <div className="top-content">
        <Link to={"/"} className="page-navbar-item">
          <img src={A1Drake} className="w-100 img-fluid" />
        </Link>
      </div>
      <div className="middle-content">
        {!publicAccess && <NavLink className="page-navbar-item with-text" to="/players">
          <i className="fal fa-user"></i>
          <span className="text">PLAYERS</span>
        </NavLink>}
        <NavLink className="page-navbar-item game" to="/tft">
          <img src={TftLogo} alt="TFT Logo" className="w-100 img-fluid" />
        </NavLink>
        <NavLink className="page-navbar-item game" to="/league">
          <img src={LeagueLogo } alt="TFT Logo" className="w-100 img-fluid" />
        </NavLink>
        <NavLink className="page-navbar-item game" to="/valorant">
          <img src={ValorantLogo} alt="TFT Logo game"  className="w-100 img-fluid" />
        </NavLink>
        {!publicAccess && <NavLink className="page-navbar-item with-text" to="/control">
          <i className="fab fa-twitch"></i>
          <span className="text">CONTROL</span>
        </NavLink>}
        {!publicAccess && <NavLink className="page-navbar-item with-text" to="/lcu">
          <i className="fal fa-gamepad"></i>
          <span className="text">LCU</span>
        </NavLink>}
      </div>
      <div className="bottom-content">
        <span className="page-navbar-item logout" onClick={logOut}>
          <i className="fal fa-sign-out"></i>
        </span>
      </div>
    </div>
  )
}

export default CustomNavbar;

/*

<Navbar expand="lg" className="bg-body-tertiary navbar bg-dark border-bottom border-body fixed-bottom" data-bs-theme="dark">
      <div className="container-fluid">
        <Navbar.Toggle aria-controls="navbar" />
        <Navbar.Collapse id="navbar">
          <Nav
            className="w-100 d-flex justify-content-center my-2 my-lg-0"
            navbarScroll
          >
            <NavLink className="nav-link" to="/players">
              PLAYERS
            </NavLink>
            <NavLink className="nav-link" to="/league">
              <img src={LeagueLogo } alt="TFT Logo" height={30} className="" />
              <span className="nav-text">League of Legends</span>
            </NavLink>
            <NavLink className="nav-link" to="/valorant">
              <img src={ValorantLogo} alt="TFT Logo" height={30} className="" />
              <span className="nav-text">Valorant</span>
            </NavLink>
            <NavLink className="nav-link" to="/tft">
              <img src={TftLogo} alt="TFT Logo" height={30} className="p-2" />
              <span className="nav-text">Teamfight Tactics</span>
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>

*/