import React, { KeyboardEvent, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { Controller, useFormContext } from "react-hook-form";
import { DarkThemeStyles } from "../../utils/DarkThemeStyles";

const components = {
  DropdownIndicator: null
};

const createOption = (label) => ({
  label,
  value: label
});

const reactHookFormFormat = (v) => {
  let result = [];

  for(let value of v) {
    result.push(value.value);
  }

  return result.join(";");
}

export const CreatableInputOnly = (props) => {
  const value = (props.value || "").split(";").filter(a => a ? true : false).map(a => createOption(a))

  const [inputValue, setInputValue] = useState("");

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setInputValue('');
        event.preventDefault();
        props.onChange(reactHookFormFormat([...value, createOption(inputValue)]));
    }
  };

  return (
    <CreatableSelect
      components={components}
      inputValue={inputValue}
      isClearable
      isMulti
      menuIsOpen={false}
      styles={DarkThemeStyles}
      onChange={(newValue) => {
        props.onChange(reactHookFormFormat(newValue));
      }}
      onKeyDown={handleKeyDown}
      onInputChange={(newValue) => setInputValue(newValue)}
      placeholder={props.placeholder}
      value={value}

    />
  );
};

export const CreatableInputOnlyWithController = (props) => {
  const { control } = useFormContext()
  const { ...rest } = props;

  return (
    <Controller
      name={props.name}
      control={control}
      defaultValue={props.defaultValue}
      render={({ field: { onChange, onBlur, value, ref, name }, fieldState: {invalid, error} }) => {
        return (
          <div className="form-group mb-3">
            <label className="form-label">{props.label}</label>
            <CreatableInputOnly
              onChange={(value) => {
                onChange(value); // First call the <Controller>-fed onChange event.
              }}
              onBlur={onBlur}
              value={value}
              name={name}
              {...rest}
            />
            {props.hint && <p className="mb-0 mt-1 text-muted">{props.hint}</p>}
          </div>
        );
      }}
    />
  );
};
