import { useState, useContext, useEffect } from "react";
import { useInterval } from "usehooks-ts";
import axios from "axios";
import { set } from "react-hook-form";
import moment from "moment";
import _ from "lodash";
import { DataContext } from "../DataWrapper/DataWrapper";
import getTftIconUrl from "../utils/TftIconUrl";
import classNames from "classnames";
import "./LCUClient.scss"
import { TftSets, useTftPages } from "../utils/TftPages";
import { ReactComponent as Star } from "../../assets/images/black-star-icon.svg";
import Select from "react-select";
import { DarkThemeStyles } from "../utils/DarkThemeStyles";
import { CreatableInputOnly } from "../Forms/CreatableInput/CreatableInput";
import { MatchTags } from "../utils/Tags";
import { usePlayers } from "../utils/Players";

const LCUClient = () => {

  const [connected, setConnected] = useState(false);
  const {players} = usePlayers("tft");
  const [puuid, setPuuid] = useState("");

  useInterval(() => {
    axios.get("http://localhost:4793/ping").then(res => {
      setConnected(true);
    }).catch(err => {
      setConnected(false);
    })
  }, 1000);

  if (!connected) {
    return <div className="container-fluid">
      <div className="row mt-2">
        <div className="col-12">
          <div className="alert alert-danger">Not connected</div>
        </div>
      </div>
    </div>
  }

  return <div className="container-fluid">
      <div className="row mt-2">
        <div className="col-12 col-xl-5">
          <PuuidFinder />
          <TftPlayerSelect players={players} setPuuid={setPuuid}/>
        </div>
        <div className="col-12 col-xl-7">
          <MatchFetcherForPuuid players={players} puuid={puuid} setPuuid={setPuuid} />
        </div>
      </div>
    </div>
}

const TftPlayerSelect = ({players, setPuuid}) => {

  const [search, setSearch] = useState("");

  let availablePlayers = search.length > 0 ? players.filter(a => ((a.nickname || "").toLowerCase().includes(search.toLowerCase()))) : players
  availablePlayers = _.sortBy(availablePlayers, a => a.active == undefined ? 0 : (a.active ? 0 : 1));

  return <div className="card mt-2 mb-3">
    <div className="card-header">
      PLAYERS
    </div>
    <ul className="list-group list-group-flush">
      <i className="list-group-item p-0">
        <div className="input-group">
          <input className="form-control search-player" type="text" placeholder="Search for Name" value={search} onChange={a =>  {
            setSearch(a.target.value);
          }} />
          {search && <span className="clear-search" onClick={() => {
            setSearch("");
          }}>CLEAR</span>}
        </div>
      </i>
      {availablePlayers.length == 0 && <li className="list-group-item">No Players found with '{search}'</li>}
      {availablePlayers.map((p, i) => {
        //console.log(p);
        let active = p.active == undefined ? true : p.active
        return (<div className={"list-group-item list-group-item-action"} key={"tft_player_" + p.id} onClick={() => {
          setPuuid(p.tftTournamentPuuid);
        }}>
          {p.nickname}
          <span className={classNames("badge", "mx-2", active ? "bg-success" : "bg-danger")}>{active ? "active" : "inactive"}</span>
          <span className="badge bg-primary">{p.tftTournamentPuuid}</span>
        </div>)
      })}
    </ul>
  </div>
}

const MatchFetcherForPuuid = ({players, setPuuid, puuid}) => {
  
  const [matches, setMatches] = useState([]);
  const [matchId, setMatchId] = useState(undefined)
  let match = matches.find(a => a.metadata.match_id == matchId);

  useEffect(() => {
    setMatches([])
    setMatchId(undefined)
  }, [puuid])

  const fetch = () => {
    if(!puuid) {
      return;
    }

    try {
      axios.get("http://localhost:4793/lcu/tft/matchhistory/" + puuid).then(res => {
        if(res.data.error) {
          setMatches([]);
        } else {
          setMatches(res.data.data);
        }
      })
    } catch (err) {
      setMatches([]);
    }
  }

  const setCallbackMatch = (data) => {
    setMatches([]);
    setPuuid("");
  }

  return <div className="row">
    <div className="col-12 col-xl-8">
      <input className="form-control" value={puuid} onChange={(e) => setPuuid(e.currentTarget.value)} />
    </div>
    <div className="col-12 col-xl-4">
      <span className={"btn btn-primary d-block w-100 " + (puuid ? "" : "disabled")} disabled={!puuid} onClick={fetch}>Search</span>
    </div>
    <div className="col-12">
      {(matchId && match) && <MatchUploader match={match} players={players}/>}
      <Matches matches={matches} players={players} callback={setMatchId} puuid={puuid}/>
    </div>
  </div>
}

const MatchUploader = ({players, match}) => {

  let dateTime = moment(Number(match?.json?.game_datetime))
  let gameId = match.metadata.match_id;
  let sortedParticipants = _.sortBy(match.json.participants, a => {
    return a.placement;
  })

  const {tftData} = useContext(DataContext);
  const setData = tftData["set_" + match.json.tft_set_number];
  const championStats = setData.champions;

  const {sites, setSite, site} = useTftPages()
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  
  const [response, setResponse] = useState(undefined);

  const uploadMatch = () => {
    setLoading(true);

    axios.post("/backend/tft/matches/add", {
      tags: tags,
      match: match,
      siteId: site.id
    }).then(() => {
      setResponse("success");
    }).catch(err => {
      setResponse("error");
    }).finally(() => {
      setLoading(false);
    })
  }

  const onChangeSite = (data) => {
    setSite(sites.find(a => a.id == data.value));
  }

  const handleTagsChange = (selectedOptions) => {
    setTags(selectedOptions);
  }

  let siteOptions = sites.filter(a => a.isA1eSports).map(a => { return { label: a.title, value: a.id}});

  if(loading) {
    <div className="alert alert-warning">Match is getting uploaded</div>
  }

  if(response == "success") {
    return <div className="alert alert-success" onClick={() => setResponse(undefined)}>Match uploaded!</div>
  }

  if(response == "error") {
    return <div className="alert alert-danger" onClick={() => setResponse(undefined)}>Match not uploaded! Something went wrong</div>
  }

  return <div className="row mt-2">
    <div className="col-12">
      <hr />
      <div className="row mb-2">
        <div className="col-4">
          <Select
            options={siteOptions}
            value={siteOptions.find(a => a.value === site.id)}
            onChange={onChangeSite}
            styles={
              DarkThemeStyles
            }
            placeholder="Search for Set"
          />
        </div>
        <div className="col-5">
        <Select
          isMulti
          name="colors"
          value={tags}
          options={MatchTags}
          onChange={handleTagsChange}
          placeholder="Set Tags"
          styles={
            DarkThemeStyles
          }
        />
        </div>
        <div className="col-3">
          <span className={classNames("btn btn-primary d-block w-100", tags.length > 0 ? "" : "disabled")} disabled={tags.length == 0} onClick={uploadMatch}>Upload Match</span>
        </div>
      </div>
      <table className="table table-bordered table-striped table-dark">
        <tbody>
          <tr>
            <th colSpan={2} className="text-start">{gameId}</th>
            <th colSpan={1}className="text-center">{match.json.tft_set_number}</th>
            <th colSpan={20}>{dateTime.format("DD.MM.YYYY HH:mm")}</th>
          </tr>
           {sortedParticipants.map(player => {

            let sortedUnits = _.sortBy(player.units, a => a.tier).reverse()


            return <tr>
                <td className="text-center">{player.placement}</td>
                <td>{players.find(b => b.tftTournamentPuuid == player.puuid)?.nickname || player.puuid}</td>
                {sortedUnits.map(unit => {
                  let imageUrl = getTftIconUrl(unit.character_id, championStats.data, "latest");
                  return <td className="p-0" style={{width: "40px"}}><img src={imageUrl} style={{width: "40px"}}/></td>
                })}
                {_.times(12 - sortedUnits.length).map( a => {
                  return <td></td>
                })}
              </tr>
           })}
        </tbody>
      </table>
    </div>
  </div>
}

const Matches = ({puuid, matches, players, callback}) => {
  return <div className="row mt-2">
    <div className="col-12">
      <table className="table table-bordered table-striped table-dark">
        <thead>
          <tr>
            <th>GameID</th>
            <th>SET</th>
            <th>DATE</th>
            <th>PLAYER</th>
            <th>PLAYERS</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {matches.map(a => {return <Match key={a.json.game_id} puuid={puuid} match={a} players={players} callback={callback} />})}
        </tbody>
      </table>
    </div>
  </div>
}

const Match = ({puuid, match, players, callback}) => {
  let dateTime = moment(Number(match.json.game_datetime))
  let gameId = match.metadata.match_id;

  if(match.json.tft_set_number < 10) return;

  let sortedParticipants = _.sortBy(match.json.participants, a => {
    return a.placement;
  })

  let participants = sortedParticipants.find(a => a.puuid == puuid);
  let player = players.find(a => a.tftTournamentPuuid == puuid);
  
  if(!participants) return;

  return <tr>
    <td style={{minWidth: "150px"}} className="text-start">{gameId}</td>
    <td style={{minWidth: "50px"}} className="text-center">{match.json.tft_set_number}</td>
    <td style={{minWidth: "140px"}}>{dateTime.format("DD.MM.YYYY HH:mm")}</td>
    <td style={{minWidth: "180px"}}><span className="badge bg-success me-1">{participants?.placement}</span>{player.nickname}</td>
    <td>{sortedParticipants.filter(a => a.puuid != puuid).map(a => {
      return <span className="badge bg-primary">{players.find(b => b.tftTournamentPuuid == a.puuid)?.nickname || a.puuid}</span>
    })}</td>
    <td><span className="btn btn-primary w-100 h-100" onClick={() => {
      callback(gameId);
    }}>Select</span></td>
  </tr>
}

const PuuidFinder = () => {
  
  const [gameName, setGameName] = useState("");
  const [tagLine, setTagLine] = useState("eProd");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const search = () => {
    if(gameName && tagLine) {
      axios.get(`http://localhost:4793/lcu/puuids/${encodeURIComponent(gameName)}/${encodeURIComponent(tagLine)}`).then(res => {
        if(res.data.error) {
          setError(res.data.message);
        } else {
          setSuccess(res.data.puuid);
        }
      }).catch((err) => {
        setError(err)
      })
    }
  }

  return <div className="card">
    <div className="card-header">
      PUUID Finder
    </div>
    <div className="card-body">
      <div className="form-group mb-3">
        <label className="form-label mb-0" htmlFor="gameName">Game Name</label>
        <input type="text" id="gameName" className="form-control" placeholder="zb. XD0M3" value={gameName} onChange={e => {setGameName(e.currentTarget.value)}} />
      </div>
      <div className="form-group mb-3">
        <label className="form-label mb-0" htmlFor="tagName">Tag Line</label>
        <input type="text" id="tagLine" className="form-control" placeholder="zb. eProd" value={tagLine} onChange={e => {setTagLine(e.currentTarget.value)}} />
      </div>
      <div className="text-start">
        <span className={"btn btn-primary btn-sm d-block w-100 " + ((gameName && tagLine) ? "" : "disabled")} disabled={(gameName && tagLine) ? false : true} onClick={search}>Search for PUUID</span>
      </div>
      {(success || error) && <hr />}
      {success && <div className="alert alert-success mt-2 mb-0">
        {success}  
      </div>}
      {error && <div className="alert alert-danger mt-2 mb-0">
        {success}  
      </div>}
    </div>
  </div>
}

export default LCUClient;