import _ from "lodash"

const TftGeneralStats = ({player , stats}) => {

  let tier = (stats?.rank.tier || "").toLowerCase();

  let averagePosition = 0;
  let sumPositions = 0;
  for(let i = 1; i < 9; i++) {
    sumPositions += stats.positions[i] * i
  }
  if(stats.games > 0) averagePosition = (sumPositions / stats.games); 
  
  return (
    <div className="card">
      <div className="card-header">
        GENERAL STATS
      </div>
      <div className="card-body py-2">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="row justify-content-center">
              <div className="col-6 col-lg-3">
                <img src={"https://raw.communitydragon.org/latest/game/assets/ux/tftmobile/particles/tft_regalia_" + tier + ".png"} alt="League" className="img-fluid w-100"/>
              </div>
              <div className="col d-flex align-items-start justify-content-center flex-column">
                <h3 className={"league " + (stats?.rank.tier || "").toLowerCase()}>{stats?.rank.tier} {stats?.rank.rank}</h3>
                <h4 className="league_points">{stats.rank.leaguePoints} LP</h4>
                <h5 className="win-loss"><span className="wins">{stats.wins}W</span> / <span className="loss">{stats.losses}L</span><span className="top-4-rate">({(stats.wins / stats.games  * 100).toFixed(2)}%) Top-4</span></h5>
              </div>
            </div>
          </div>
          <div className="col-12"><hr className="my-2" /></div>
          <div className="col-12 col-md-6">
            <h3 className="games">{stats?.games}<span className="games-sub">SPIELE</span></h3>
            <h5 className="games-description">Spiele gesamt</h5>
          </div>
          <div className="col-12 col-md-6">
            <h3 className="position">{averagePosition.toFixed(2)}<span className="games-sub">/ 8</span></h3>
            <h5 className="games-description">Average Position</h5>
          </div>
          <div className="col-12"><hr className="my-2" /></div>
          <div className="col-12 mt-1">
            <div className="row">
              <div className="col-12 d-flex align-items-start flex-wrap flex-row">
                {_.times(8).map(a => <Position position={a+1} key={"position_" + a} count={stats.positions[a+1]} />)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Position = ({position, count}) => {
  return <div className={"position-place p" + position}>
    <div className={"position-number"}>
      #{position}
    </div>
    <div className="position-count">
      {count}
    </div>
  </div>
}

export default TftGeneralStats;