import axios from "axios";
import classNames from "classnames";
import { useEffect, useState, version } from "react"
import React from "react";

export const TftSets = [
  {
    value: "set_10",
    label: "Set 10 - Remix Rumble",
    version_1: "14.4",
    version_2: "14.4.1",
    default: false,
    setNumber: 10,
  },{
    value: "set_11",
    label: "Set 11 - Inkborne Fables",
    version_1: "14.6",
    version_2: "14.6.1",
    default: true,
    setNumber: 11,
  },{
    value: "a1_2024_set11_1",
    set: "set_11",
    label: "A1 Prismatic Legends - Set 11 - 1",
    version_1: "14.6",
    version_2: "14.6.1",
    a1esports: true,
    setNumber: 11,
  },{
    value: "a1_2024_set11",
    set: "set_10",
    version_1: "14.4",
    version_2: "14.4.1",
    label: "Debug / Test",
    a1esports: true,
    setNumber: 11,
  },{
    value: "set_12",
    set: "set_12",
    version_1: "14.15",
    version_2: "14.15.1",
    label: "Set 12 - Magic and Mayhem",
    a1esports: false,
    setNumber: 12
  }
]


export const useTftPages = () => {
  const [sites, setSites] = useState([]);
  const [site, setSite] = useState(undefined);
  
  useEffect(() => {
    axios.get("/backend/tft/pages").then(res => {
      setSites(res.data);
      if(!site) setSite(res.data.find(a => a.active));
    })
  }, [])

  return {
    sites, setSite, site
  }
} 

export const TftPageSelect = ({sites = [], setSite, site}) => {
  return <div className="card mt-2 mb-3">
    <div className="card-header">
      TftPages
    </div>
    <ul className="list-group list-group-flush">

      {sites.length == 0 && <li className="list-group-item">No Pages found</li>}
      {sites.map((s, i) => {
        return (<span className={classNames("list-group-item", "list-group-item-action", s.id == site?.id ? "active" : "")} key={"tft_page_" + s.id} onClick={() => {
          setSite(s); 
        }}>
          {s.title}
        </span>)
      })}
    </ul>
  </div>
}

export const formatSitesForSelect = (sites) => {
  return sites.map(a => {
    return {
      value: a.id,
      label: a.title
    }
  })
}