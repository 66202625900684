import { useContext, useState } from "react";
import { DataContext } from "../../../DataWrapper/DataWrapper";
import _ from "lodash";
import { ObjectToArray } from "../../../utils/ObjectToArray";
import StatHighlight from "./StatHighlight";

const TftItemsStats = ({player, stats, site}) => {

  const {tftData} = useContext(DataContext);
  const setData = tftData[`set_${site.tftSet}`];
  const itemsStats = setData.items;
  const [showAll, setShowAll] = useState(false);

  if(!stats) return;

  let unsorted = ObjectToArray(stats.itemStats);
  let sorted = _.sortBy(unsorted, a => a.obj.used).reverse();
  sorted = showAll ? sorted : sorted.slice(0, 5);

  return <div className="card mt-2 mb-2">
  <div className="card-header position-relative">
    Item Stats
    <span className="show-all" onClick={() => {
      setShowAll(prev => !prev);
    }}>{showAll ? "Show 5" : "Show all"}</span>
  </div>
  <div className="card-body p-1">
    <ul className="list-group">
      {sorted.map(a => {
        let item = Object.values(itemsStats.data).find(b => b.id == a.name);
        let placeholder = "https://ddragon.leagueoflegends.com/cdn/" + site.leagueVersion + ".1/img/tft-item/@@name@@";
        let imageUrl = placeholder.replace("@@name@@", item?.image?.full);
        let label = item?.name || a.name;

        if(label == "TFT_Item_EmptyBag") return;

        return <StatHighlight
          name={a.name}
          label={label}
          imageUrl={imageUrl}
          stat1Label={"Games"}
          stat1={a.obj.used}
          key={a.name}
        />
      })}
    </ul>
  </div>
</div>
}


export default TftItemsStats;