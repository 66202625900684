import { useState, useEffect, useContext } from "react";
import PlayerSelect from "./PlayerSelect";
import NewPlayer from "./NewPlayer";
import EditPlayer from "./EditPlayer";
import axios from "axios";
import { useParams } from "react-router-dom";
import { UserContext } from "../User/User";

const Players = () => {
  const { playerId } = useParams();
  let player = undefined
  const [players, setPlayers] = useState([])
  const [refresh, setRefresh] = useState(new Date());
  const { token, publicAccess } = useContext(UserContext);

  if(playerId) player = players.find(a => a.id == playerId);

  console.log("token", token)

  useEffect(() => {
    axios.get("/backend/players", {
      "headers": {
        'Authorization': `Bearer ${token}`
      }
    }).then((res) => {
      setPlayers(res.data);
    }).catch((err) => {
      console.error(err)
    })
  }, [playerId, refresh])

  if(publicAccess) {
    return <div className="alert alert-danger m-2">No access to this page</div>
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 p-0">
          <nav className="breadcrumbs" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a className="breadcrum-link">PLAYERS</a></li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-12 col-lg-8 mb-2">
          {player && <EditPlayer player={player} key={player.flave_id} callback={setRefresh} />}
          
        </div>
        <div className="col mb-2">
          <NewPlayer callback={setRefresh} />
          <PlayerSelect player={player} players={players} />
        </div>
      </div>
    </div>)
}

export default Players;