export const DarkThemeStyles = {
  option: (defaultStyles, state) => ({
    ...defaultStyles,
    color: state.isSelected ? "white" : "var(--primary)",
    backgroundColor: state.isSelected ? "var(--primary)" : "#222",
    "&hover": {
      color: state.isSelected ? "white" : "var(--primary)"
    }
  }),
  control: (defaultStyles, state) => ({
    ...defaultStyles,
    backgroundColor: "var(--bs-body-bg)",
    color: "white",
    borderColor: "var(--bs-border-color)"
  }),
  singleValue: (defaultStyles) => ({ 
    ...defaultStyles, 
    color: "#fff"
  }),
  input: (defaultStyles, state) => ({
    ...defaultStyles, 
    color: "#fff",
  }),
  multiValue: (defaultStyles) => ({
    ...defaultStyles,
    border: "1px solid white",
    backgroundColor: "#222",
    color: "var(--primary)",
  }),
  multiValueLabel: (defaultStyles) => ({
    ...defaultStyles,
    color: "white",
    fontSize: "15px",
    fontWeight: "normal",
    marginRight: "10px"
  }),
  menu: (defaultStyles) => ({
    ...defaultStyles,
    backgroundColor: "var(--bs-body-bg)",
    zIndex: 20
  })
}