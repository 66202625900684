import { useContext, useEffect, useState } from "react"
import "./endgame.scss";
import axios from "axios";
import TftBackgroundImage from "../../../assets/images/Background_plain.png"
import _ from "lodash";
import classNames from "classnames";
import { DataContext } from "../../DataWrapper/DataWrapper";
import getTftIconUrl from "../../utils/TftIconUrl";
import { TftSets } from "../../utils/TftPages";
import { ReactComponent as Star } from "../../../assets/images/black-star-icon.svg";

const Endgame = () => {
  const [data, setData] = useState(undefined);

  useEffect(() => {
    axios.get("/backend/tft-stream/match/preview?version=2").then(res => {
      setData(res.data);
    });
  }, [])

  if (!data) return;

  return <div className="tft-endgame-wrapper">
    <Players data={data} />
  </div>
}

const Players = ({ data }) => {

  let match = data.match.match;

  let participants = match?.json?.participants || match?.info?.participants || [];
  let sortedParticipants = _.sortBy(participants, a => a.placement)

  console.log("match", match)

  return (
    <div className="players-wrapper">
      {sortedParticipants.map((player, index) => {
        return <Player participant={player} players={data.players} data={data.match} key={player.puuid}/>
      })}
    </div>
  )
}

const Player = ({ participant, players, data }) => {

  let player = players.find(b => b.tftTournamentPuuid == participant.puuid || b.tftPuuid == participant.puuid)
  let playerName = player?.nickname || `Player ${participant.placement}`
  let units = _.sortBy(participant.units, a => a.tier).reverse();

  let set = data.tftSet;

  return (
    <div className="player-wrapper">
      <Hexagon extraClass={`place${participant.placement}`}>
        {participant.placement}
      </Hexagon>
      <div className="player-name">
        {playerName}
      </div>
      <Hexagon extraClass={"lvl"}>
        <span className="lvl-text">LVL</span>{participant.level}
      </Hexagon>
      <Traits traits={participant.traits} set={set} />
      <div className="units">
        
        
        {units.map(a => {
          return <UnitHexagon unit={a} set={set} champion={a.character_id} puuid={participant.puuid}/>
        })}
      </div>
    </div>
  )
}

const Hexagon = ({ extraClass, ...props }) => {
  return <div className={classNames("hexagon", extraClass)}>
    <div className={classNames("hexagon", "inner")}>
      {props.children}
    </div>
  </div>
}

const UnitHexagon = ({ extraClass, unit, champion, set, puuid, ...props }) => {

  const { tftData } = useContext(DataContext);
  const setName = `set_${set}`;
  const setData = tftData[setName];
  const champions = setData.champions.data;
  const version = TftSets.find(a => a.value == setName);

  let imageUrl = getTftIconUrl(champion, champions, version.version_1);

  //console.log(version.version_1, champion, imageUrl, champions)

  return <div className="unit-wrapper" data-champion={champion}>
    <UnitStars tier={unit.tier} unit={champion} puuid={puuid}/>
    <div className={classNames("hexagon", "unit", extraClass)}>
      <div className={classNames("hexagon", "inner", "unit-inner")} style={{ backgroundImage: `url(${imageUrl})` }}>
      </div>
    </div>
    <Items items={unit.itemNames} set={set} puuid={puuid}/>
  </div>
}

const UnitStars = ({ tier, set, puuid, unit }) => {
  return <span className="stars">{_.times(tier).map(a => <Star className={"star lvl" + tier} key={puuid + "_" + unit + "_" + a} />)}</span>
}

const Traits = ({traits, set, puuid}) => {

  const { tftData } = useContext(DataContext);
  const setName = `set_${set}`;
  const setData = tftData[setName];
  const traitStats = setData.traits;
  const version = TftSets.find(a => a.value == setName);

  return <div className="traits d-none">{_.orderBy(traits, b => b.tier_total - b.tier_current).map(a => {
    let placeholder = "https://ddragon.leagueoflegends.com/cdn/" + version.version_2 + "/img/tft-trait/@@name@@";
    let imageUrl = placeholder.replace("@@name@@", traitStats.data[a.name]?.image?.full);
    let label = traitStats.data[a.name]?.name || a.name;

    let traitTier = "none"

    if (a.tier_total == 1) {
      if(a.tier_current == 1) traitTier = "gold";
    }

    if (a.tier_total == 2) {
      if(a.tier_current == 1) traitTier = "silber";
      if(a.tier_current == 2) traitTier = "gold";
    }

    if (a.tier_total == 3) {
      if(a.tier_current == 1) traitTier = "bronze";
      if(a.tier_current == 2) traitTier = "silber";
      if(a.tier_current == 3) traitTier = "gold";
    }

    if (a.tier_total == 4) {
      if(a.tier_current == 1) traitTier = "bronze";
      if(a.tier_current == 2) traitTier = "silber";
      if(a.tier_current == 3) traitTier = "gold";
      if(a.tier_current == 4) traitTier = "chromatic";
    }

    if(a.tier_current == 0) return;
    //console.log(label, a, traitTier, a.tier_current, a.tier_total)

    return <span className={classNames("trait", traitTier)}>
      <img src={imageUrl} className="trait-img" />
      {label}
      <span className="value">{a.num_units}</span>
    </span>
  })}</div>
}

const Items = ({items, set, puuid}) => {

  const { tftData } = useContext(DataContext);
  const setName = `set_${set}`;
  const setData = tftData[setName];
  const itemData = setData.items;
  const version = TftSets.find(a => a.value == setName);

  return <span className={"items"}>
    {items.map((itemId, i) => {
      let item = Object.values(itemData.data).find(a => a.id == itemId);
      if(!item) {
        console.log("No item", itemId, itemData);
      };
      let placeholder = "https://ddragon.leagueoflegends.com/cdn/" + version.version_2 + "/img/tft-item/@@name@@";
      let imageUrl = placeholder.replace("@@name@@", item?.image?.full);

      return <img className="item" src={imageUrl} key={puuid + "_" + itemId + "_" + i}/>
    })}
  </span>
}

export default Endgame;