import { useFormContext, Controller } from "react-hook-form";
import classNames from "classnames";
import ErrorMessage from "../../utils/ErrorMessage";

const Checkbox = ({label, name, required = false}) => {

    const { control } = useFormContext();
    let htmlId = "checkbox_" + name;

    return (<Controller
      name={name}
      control={control}
      rules={{required: required}}
      render={({ field: { onChange, onBlur, value, ref }, fieldState: {invalid, error} }) => (
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="1" id={htmlId} onChange={onChange} checked={value} />
          <label class="form-check-label" htmlFor={htmlId}>
            {label}
          </label>
        </div>
      )} 
    
    />);
}

export default Checkbox;