import { useContext } from "react";
import { UserContext } from "../User/User";
import { Link } from "react-router-dom";

const LeagueOfLegends = () => {
  
  const {publicAccess} = useContext(UserContext);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 p-0">
          <nav className="breadcrumbs" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a className="breadcrum-link">League of Legends</a></li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12 text-end">
          {!publicAccess && <Link to={"/league/stream"} className="btn btn-primary btn-sm">
            Stream
          </Link>}
        </div>
      </div>
    </div>
  );
}

export default LeagueOfLegends;