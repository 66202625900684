import axios from "axios";
import classNames from "classnames";
import { useState } from "react";

const StreamLeagueOfLegends = () => {

  const [match, setMatch] = useState(undefined)

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 p-0">
          <nav className="breadcrumbs" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a className="breadcrum-link">League of Legends</a></li>
              <li className="breadcrumb-item"><a className="breadcrum-link">Stream</a></li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-12 col-xl-4">
          <FetchMatch setMatch={setMatch} />
        </div>
        <div className="col-12 col-xl-6">

        </div>
      </div>
    </div>
  );
}

const FetchMatch = ({ setMatch }) => {

  const [gameId, setGameId] = useState("");

  const onChange = (e) => {
    setGameId(e.currentTarget.value);
  }

  const fetchMatch = () => {
    if(!gameId) return;

    axios.post("/backend/league/stream/match/fetch", {
      gameId: gameId,
      version: "live"
    }).then(res => {
      console.log(res);
      if(res.error) {
        alert("Error fetching match: " + res.error);
      } else {
        setGameId("");
        setMatch(res.data);
      }
    })
  }

  return <div className="card">
    <div className="card-header">Fetch Match</div>
    <div className="card-body">
      <div className="form-group mb-3">
        <label className="form-label">GameID</label>
        <input type={"text"} className={classNames("form-control")} placeholder={"GameID"} value={gameId} onChange={onChange} />
      </div>
      <span className={classNames("btn btn-primary", gameId ? "" : "disabled")} onClick={fetchMatch}>
        Fetch GameID
      </span>
    </div>
  </div>
}

export default StreamLeagueOfLegends;