import axios from "axios";
import { useEffect, useState } from "react";

export const usePlayers = (game, page = "") => {
  const [player, setPlayer] =  useState(undefined)
  const [players, setPlayers] = useState([]);

  useEffect(() => {
    console.log("Fetching players", game, page);
    axios.get("/backend/" + game + "/players" + "?page=" + page).then(res => {
      setPlayers(res.data);
    })
  }, [page])

  return {
    player, setPlayer, players
  }
}