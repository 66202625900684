import { useState, useEffect } from "react";
import { DarkThemeStyles } from "../utils/DarkThemeStyles";
import Select from "react-select";
import axios from "axios";
import { Link } from "react-router-dom";
import classNames from "classnames";
import _ from "lodash";

const PlayerSelect = ({player, players, callback}) => {

  const [search, setSearch] = useState("");

  let availablePlayers = search.length > 0 ? players.filter(a => ((a.nickname || "").toLowerCase().includes(search.toLowerCase()))) : players
  availablePlayers = _.sortBy(availablePlayers, a => a.active == undefined ? 0 : (a.active ? 0 : 1));

  return <div className="card mt-2 mb-3">
    <div className="card-header">
      PLAYERS
    </div>
    <ul className="list-group list-group-flush">
      <i className="list-group-item p-0">
        <div className="input-group">
          <input className="form-control search-player" type="text" placeholder="Search for Name" value={search} onChange={a =>  {
            setSearch(a.target.value);
          }} />
          {search && <span className="clear-search" onClick={() => {
            setSearch("");
          }}>CLEAR</span>}
        </div>
      </i>
      {availablePlayers.length == 0 && <li className="list-group-item">No Players found with '{search}'</li>}
      {availablePlayers.map((p, i) => {
        let active = p.active == undefined ? true : p.active
        return (<Link className={"list-group-item list-group-item-action " + (player?.id == p.id ? "active" : "")} key={"tft_player_" + p.id} to={`/players/${p?.id}`}>
          {p.nickname}
          <span className={classNames("badge", "ms-2", active ? "bg-success" : "bg-danger")}>{active ? "active" : "inactive"}</span>
        </Link>)
      })}
    </ul>
  </div>
}


export default PlayerSelect;