import { Link } from "react-router-dom";
import { PlayerUnit } from "./PlayerUnit";

const StreamTft = () => {
  return (
    <div className="container-fluid">
      <div className="row my-2">
        <div className="col-12 text-end">
          <Link to={"/tft/matches"} className="btn btn-primary btn-sm me-2">
            Matches
          </Link>
          <Link to={"/tft"} className="btn btn-primary btn-sm me-2">
            Players
          </Link>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-12 col-xl-6">
          <PlayerUnit />
        </div>
        <div className="col-12 col-xl-6">
          
        </div>
      </div>
    </div>
  );
}

export default StreamTft;