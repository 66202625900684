import "./Login.scss";
import { useForm, FormProvider } from "react-hook-form"
import TextInput from "../Forms/TextInput/TextInput"
import A1Drake from "../../assets/images/a1drake.png";
import axios from "axios";

const Login = ({
  setToken, token, isPublic, setIsPublic
}) => {

  const methods = useForm();
  const onSubmit = async (loginData) => {
    let response = await axios.post(
      "/backend/login",
      loginData
    )

    console.log("LOGIN", loginData, response.data.errors)

    let errors = response?.data?.errors || [];

    if(response?.data?.status === "ok") {
      console.log("LOGIN OK", response.data.token)
      setToken(response.data.token);
      return;
    }

    for (let error of errors) {
      methods.setError(error?.key, {
        type: error?.type,
        message: error?.message,
      })
    }
  }

  const accessPublic = () => {
    setIsPublic(true);
  }

  return <div className="login-wrapper">
    <img src={A1Drake} className="login-logo" />
    <FormProvider {...methods}>
        <form className="login-form" onSubmit={methods.handleSubmit(onSubmit)}>
          <h1 className="login-title text-center">Login</h1>
          <TextInput name={"username"} label="Username" placeholder="" />
          <TextInput name={"password"} label="Password" type="password"/>
          <button type="submit" className="btn btn-primary w-100 p-2">Submit</button>
          <div className="form-divider">
            <div className="slot-divider"><span className="divider"></span></div>
            <div className="slot-divider-middle">No Account?</div>
            <div className="slot-divider"><span className="divider"></span></div>
          </div>
          <button type="button" className="btn btn-warning w-100 p-2" onClick={accessPublic}>Access Site as a Public User</button>
        </form>
      </FormProvider>
  </div>
}

export default Login;