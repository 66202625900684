import { useState, useEffect, useContext } from "react"
import { useInterval } from "usehooks-ts";
import axios from "axios";
import Select from 'react-select';
import { DarkThemeStyles } from "../../utils/DarkThemeStyles";
import { DataContext } from "../../DataWrapper/DataWrapper";
import getTftIconUrl from "../../utils/TftIconUrl";
import { useTftPages } from "../../utils/TftPages";
import { usePlayers } from "../../utils/Players";

const SET = 11;

export const PlayerUnit = () => {

  const { sites, setSite, site} = useTftPages()
  const { players, setPlayer, player } = usePlayers("tft");
  const [championId, setChampionId] = useState(undefined)

  let {tftData} = useContext(DataContext)

  let playerOptions = players.map(p => { return {label: p.nickname, value: p.id }})
  let siteOptions = sites.map(s => { return {label: s.title, value: s.id }})

  let championOptions = []

  useInterval(() => {
    axios.get("/backend/tft/stream/selection").then(res => {
      console.log(res.data);
      if(res.data?.playerId) {
        setPlayer(players.find(a => a.id == res.data?.playerId));
      }
      if(res.data?.pageId) {
        setSite(sites.find(a => a.id == res.data?.pageId));
      }
      if(res.data?.unitId) {
        setChampionId(res.data?.unitId);
      }
    }).catch(err => {
      console.log(err);
    })
  }, 1000);

  let championStats = {}
  let champions = {}

  if(site) {
    const setData = tftData[`set_${site.tftSet}`];
    championStats = setData.champions;
    champions = Object.values(championStats.data);
    championOptions = champions
      .filter(a => !a.id.includes("TFTTutorial"))
      .filter(a => !a.id.includes("TFT3"))
      .map(a => championOption(a)).concat([

      ]);
  }

  const changePlayer = (data) => {
    axios.get(`/backend/tft/stream/select_player/${data.value}`);
    setPlayer(players.find(a => a.id == data.value));
  }

  const changePage = (data) => {
    axios.get(`/backend/tft/stream/select_page/${data.value}`);
    setSite(sites.find(a => a.id == data.value));
  }

  const changeUnit = (data) => {
    axios.get(`/backend/tft/stream/select_unit/${data.value}`);
    setChampionId(data.value);
  }

  console.log(championId, championOptions[0])

  return <div className="card">
    <div className="card-header">PLAYER UNIT</div>
    <div className="card-body">
      <div className="row">
        <div className="col-12">
          <Select
            onChange={changePlayer}
            options={playerOptions}
            value={playerOptions.find(a => a.value == player?.id)}
            styles={
              DarkThemeStyles
            }
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-12">
        <Select
            onChange={changePage}
            options={siteOptions}
            value={siteOptions.find(a => a.value == site?.id)}
            styles={
              DarkThemeStyles
            }
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-12">
        <Select
            onChange={changeUnit}
            options={championOptions}
            value={championOptions.find(a => a.value == championId)}
            styles={
              DarkThemeStyles
            }
          />
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-12">
            <PreviewData players={players} champions={championStats.data} page={site} />
        </div>
      </div>
    </div>
  </div>
}

const PreviewData = ({players, champions, page}) => {

  const [stats, setStats] = useState(undefined)

  useInterval(() => {
    axios.get("/backend/tft/stream/data_source").then(res => {
      setStats(res.data);
    }).catch(err => {
      console.log(err);
    })
  }, 1000);

  if(!stats) return;

  let stat = stats[0];

  if(!stat) return;


  console.log(stat.unitId, champions, page.leagueVersion)

  let imageUrl = getTftIconUrl(stat.unitId, champions, page.leagueVersion);

  return <div className="row">
    <div className="col-2 pe-0">
      <img src={imageUrl} className="img-fluid w-100 img-champion"/>
    </div>
    <div className="col">
      <div className="row">
        <div className="col-3">
          <span className="player-name">{players.find(a => a.id == stat.playerId).nickname}</span>
        </div>
        <div className="col">
          <div className="row">
            <div className="col-12 preview-data">{stat.games}</div>
            <div className="col-12 preview-data-title">Games</div>
          </div>
        </div>
        <div className="col">
          <div className="row">
            <div className="col-12 preview-data">{stat.wins}</div>
            <div className="col-12 preview-data-title">Wins</div>
          </div>
        </div>
        <div className="col">
          <div className="row">
            <div className="col-12 preview-data">{stat.losses}</div>
            <div className="col-12 preview-data-title">Loss</div>
          </div>
        </div>
        <div className="col">
          <div className="row">
            <div className="col-12 preview-data">{stat.averagePosition}</div>
            <div className="col-12 preview-data-title">Avg. Position</div>
          </div>
        </div>
      </div>
    </div>
  </div>
}

const championOption = (champion) => {
  return {
    value: champion.id,
    label: champion.name + " | " + champion.id
  }
}