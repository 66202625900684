export const MatchTags = [
  "Round 1",
  "Round 2",
  "Round 3",
  "Round 4",
  "Round 5",
  "Top 64",
  "Top 16 - GSC",
  "Top 32",
  "Top 16 / 1",
  "Top 16 / 2",
  "Finale",
  "Extra",
  "Lobby 1",
  "Lobby 2",
  "Lobby 3",
  "Lobby 4",
  "Lobby 5",
  "Lobby 6",
  "Lobby 7",
  "Lobby 8",
  "Lobby 9",
  "Lobby 10",
  "Lobby 11",
  "Lobby 12",
  "Lobby 13",
  "Lobby 14",
  "Lobby 15",
  "Lobby 16",
  "Stream",
  "Marker 1",
  "Marker 2",
  "Marker 3",
  "Marker 4",
  "Marker 5",
].map(a => ({
  label: a,
  value: a
}))