const getTftIconUrl = (c, champions, version = "14.4") => {
  if(c == "TFT11_Kayle") {
    return "https://raw.communitydragon.org/latest/game/assets/characters/tft11_kayle/hud/tft11_kayle_square.tft_set11.png";
  } else if (c == "TFT11_Darius") {
    return "https://raw.communitydragon.org/latest/game/assets/characters/tft11_darius/hud/tft11_darius_square.tft_set11.png";
  } else if(c == "TFT11_Xayah") {
    return "https://raw.communitydragon.org/14.6/game/assets/characters/tft11_xayah/hud/tft11_xayah_square.tft_set11.png"
  } else if(c == "TFT12_Yuumi") {
    return "https://raw.communitydragon.org/14.15/game/assets/characters/tft12_yuumi/hud/tft12_yuumi_square.tft_set12.png"
  }
  
  let champion = getDataFromName(champions, c);
  let imagePath = (champion?.image?.full || "").toLowerCase();
  let championName = c.toLowerCase();
  console.log("getTftIconUrl", version,"c", c,"champion", champion, champions)

  let dotCount = imagePath.split(".").length - 1; 
  let placeholder = "https://raw.communitydragon.org/" + version + "/game/assets/characters/@@champion_name@@/hud/@@image@@.png"

  console.log("dotCount", dotCount, imagePath);

  if(dotCount == 1) {
    let identifier = imagePath.split(".")[0];
    let image = `${identifier}_square`;

    return placeholder.replace("@@champion_name@@", championName).replace("@@image@@", image);
  } else if(dotCount == 2) {
    let identifier = imagePath.split(".")[0];
    let identifier2 = imagePath.split(".")[1];
    let image = `${identifier}_square.${identifier2}`;

    return placeholder.replace("@@champion_name@@", championName).replace("@@image@@", image);
  }
}

export function getDataFromName(data, name) {
  let result = data[name];
  if(result) return result;
  let keys = Object.keys(data);

  for(let key of keys) {
    //console.log(key, data[key].id, name);
    if(data[key].id == name) return data[key];
  }
}

export default getTftIconUrl;