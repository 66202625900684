import { useContext, useEffect, useState } from "react"
import "./endgame.scss";
import axios from "axios";

import WaterDragon from "../../../assets/images/objectives/water.png"
import AirDragon from "../../../assets/images/objectives/air.png"
import EarthDragon from "../../../assets/images/objectives/earth.png"
import FireDragon from "../../../assets/images/objectives/fire.png"
import HextechDragon from "../../../assets/images/objectives/hextech.png"
import ChemtechDragon from "../../../assets/images/objectives/chemtech.png"
import ElderDragon from "../../../assets/images/objectives/elder.png";

import _ from "lodash";

import Herald from "../../../assets/images/objectives/herald.png";
import Grubs from "../../../assets/images/objectives/grubs.png";
import Baron from "../../../assets/images/objectives/baron.png";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const Endgame = () => {
  const [data, setData] = useState(undefined);
  const [stats, setStats] = useState(undefined)
  const [items, setItems] = useState(undefined);
  const [version, setVersion] = useState(undefined);
  const [runes, setRunes] = useState(undefined);

  useEffect(() => {
    axios.get("/backend/league-stream/match/preview").then(res => {
      setData(res.data);
    });
  }, [])

  useEffect(() => {
    axios
      .get("https://ddragon.leagueoflegends.com/api/versions.json")
      .then((res) => {
        let version = res.data[0];
        setVersion(version);

        axios.get("https://ddragon.leagueoflegends.com/cdn/" + version + "/data/en_US/runesReforged.json").then((response) => {
          setRunes(response.data);
        })

        axios
          .get(
            "https://ddragon.leagueoflegends.com/cdn/" +
            version +
            "/data/en_US/item.json"
          )
          .then((res) => {
            //console.log(res.data.data)

            let keys = Object.keys(res.data.data);
            let results = [];
            keys.map((itemKey) => {
              let item = res.data.data[itemKey];
              //console.log(item)
              if (
                (item.into || []).length == 0 &&
                (item.from || []).length != 0
              ) {
                results.push(itemKey);
              }

              if ((item.into || []).length == 1) {
                let secondItem = res.data.data[item.into[0]];

                if (secondItem && secondItem.inStore == false) {
                  results.push(itemKey);
                }
              }
            });

            setItems(results);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  if (!data) return;

  if (!items) return;
  if (!version) return;
  if (!runes) return;

  console.log(data);

  if(data.match.version == "live") return <div className="league-endgame-wrapper">
    <TeamStatsLive data={data} />
    <PlayerStatsLive data={data} />
  </div>;
}

const dragons = {
  "WATER_DRAGON": WaterDragon,
  "FIRE_DRAGON": FireDragon,
  "EARTH_DRAGON": EarthDragon,
  "AIR_DRAGON": AirDragon,
  "HEXTECH_DRAGON": HextechDragon,
  "CHEMTECH_DRAGON": ChemtechDragon,
  "ELDER_DRAGON": ElderDragon
}


const PlayerStatsLive = ({data}) => {

  const site = 1;

  return <div className="player-stats">
    <Players data={data} />
    <Graph data={data}/>
  </div>
}

const Players = ({data}) => {
  console.log(data.match.match.info.participants);
  //let participants = data?.json.participants || [];
  let bluePlayers = data.match.match.info.participants.filter(a => a.teamId == 100);
  let redPlayers = data.match.match.info.participants.filter(a => a.teamId == 200);
  
  console.log(bluePlayers[0]);
  
  let blueMaxDmg = 0;
  let redMaxDmg = 0;

  for(let player of bluePlayers) {
    if(player.totalDamageDealtToChampions > blueMaxDmg) blueMaxDmg = player.totalDamageDealtToChampions;
  }

  for(let player of redPlayers) {
    if(player.totalDamageDealtToChampions > redMaxDmg) redMaxDmg = player.totalDamageDealtToChampions;
  }

  return <div className="players">
    <div className="blue-players">
      {bluePlayers.map(a => <Player participant={a} maxDmg={blueMaxDmg} />)}
    </div>
    <div className="red-players">
      {redPlayers.map(a => <Player participant={a} maxDmg={redMaxDmg} />)}
    </div>
  </div>
}

const Player = ({participant, maxDmg = 0}) => {

  let physical = participant.physicalDamageDealtToChampions
  let magical = participant.magicDamageDealtToChampions
  let trueDamage = participant.trueDamageDealtToChampions;

  console.log(participant.riotIdGameName, physical, magical, trueDamage, maxDmg);

  const percentPhysical = (physical / maxDmg * 100).toFixed(1);
  const percentMagical = (magical / maxDmg * 100).toFixed(1);
  const percentTrue = (trueDamage / maxDmg * 100).toFixed(2);

  return <div className="player">
    <img className="player-img" src={`https://cdn.communitydragon.org/latest/champion/${participant.championId}/tile`} />
    <span className="player-name">{participant.riotIdGameName}</span>
    <div className="dmg-bar">
      <div className="bar true" style={{width: `${percentTrue}%`}}></div>
      <div className="bar magical" style={{width: `${percentMagical}%`}}></div>
      <div className="bar physical" style={{width: `${percentPhysical}%`}}></div>
    </div>
    <div className="dmg">{formatDamage(physical + magical + trueDamage)}k</div>
  </div>
}

const Graph = ({data}) => {

  let min = -2000;
  let max = 2000;
  let limit = 2000;
  let dataNew = [];

  for(let [index, frame] of Object.entries(data.match.timeline.info.frames)) {
    console.log(frame);
    let blueGold = 0;
    let redGold = 0;

    for(let i = 0; i < 5; i++) {
      let blueFrame = frame.participantFrames[i+1];
      let redFrame = frame.participantFrames[i+5];
      console.log(blueFrame, redFrame);

      if(blueFrame)blueGold += blueFrame.totalGold;
      if(redFrame)redGold += redFrame.totalGold;
    }

    console.log(blueGold, redGold);

    dataNew.push([(index - 1) * 60, blueGold - redGold])
  }

  for(let el of dataNew) {
    if(el[1] < min) min = el[1];
    if(el[1] > max) max = el[1];
  }
  //console.log(dataNew)

  const options = {
    chart: {
      type: "area",
      backgroundColor: "#11111100",
      height: 240,
      width: 715,
      style: {
        paddingTop: "10px",
        margin: "0px",
      },
    },
    title: { text: "" },
    subtitle: {},
    stroke: {
      curve: "straight",
    },
    xAxis: {
      type: "number",
      tickInterval: 300,
      gridLineWidth: 1,
      //plotLines: events,
      lineWidth: 1,
      tickColor: "transparent",
      gridLineColor: "white",
      labels: {
        formatter: (a) => {
          let b = formatTime(a.value);
          return b == "LIVE" ? "00:00" : b;
        },
        style: {
          fontFamily: "Poppins",
          color: "white",
          fontWeight: "bold",
        },
      },
    },
    yAxis: {
      title: { text: "" },
      min: min - 250,
      max: max + 250,
      type: "number",
      tickInterval: limit / 2,
      gridLineWidth: 0.2,
      gridLineColor: "#eeeeee44",
      labels: {
        formatter: (a) => Math.abs((a.value / 1000).toFixed(1)) + "k",
        style: {
          fontFamily: "Poppins",
          color: "white",
          fontWeight: "",
        },
      },
      plotLines: [
        {
          color: "var(--primary)",
          width: 1,
          value: 0,
          zIndex: 10,
        },
      ],
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "",
        data: dataNew,
        color: "#1eaad4",
        negativeColor: "#da291c",
      },
    ],
    plotOptions: {
      area: {
        marker: {
          enabled: false,
        },
      },
      areaspline: {
        fillOpacity: 0.5,
      },
    },
    legend: {
      enabled: false,
    },
  };

  return <div className="graph"><HighchartsReact
    highcharts={Highcharts}
    options={options}
    className="mt-2"
  /></div>
}

const TeamStatsLive = ({data}) => {
  //console.log("timeline", data.match.timeline);
  let match = data.match;

  let blueTeam = match.match.info.teams.find(a => a.teamId == "100");
  let redTeam = match.match.info.teams.find(a => a.teamId == "200");
  
  let playersBlue = match.match.info.participants.filter(a => a.teamId == 100);
  let playersRed = match.match.info.participants.filter(a => a.teamId == 200);

  let teamKillsBlue = 0;
  let teamKillsRed =  0;
  let teamDeathsBlue = 0;
  let teamDeathsRed = 0;
  let teamAssistsBlue = 0;
  let teamAssistsRed = 0;
  
  let goldBlue = 0;
  let goldRed = 0;
 
  for(let player of playersBlue) {
    teamKillsBlue += player.kills;
    teamDeathsBlue += player.deaths;
    teamAssistsBlue += player.assists;
    goldBlue += player.goldEarned;
  }
  for(let player of playersRed) {
    teamKillsRed += player.kills;
    teamDeathsRed += player.deaths;
    teamAssistsRed += player.assists;
    goldRed += player.goldEarned;
  }

  let objectives = []
  
  for(let frame of data.match.timeline.info.frames) {
    for(let event of frame.events) {
      if(event.type == "ELITE_MONSTER_KILL") {
        objectives.push(event);
      }
    }
  }
  
  //console.log("OBJECTIVES", objectives, blueTeam, redTeam);

  let voidGrubsBlue = blueTeam.objectives.horde.kills;
  let voidGrubsRed = redTeam.objectives.horde.kills;
  let heraldBlue = blueTeam.objectives.riftHerald.kills;
  let heraldRed = redTeam.objectives.riftHerald.kills;
  let baronBlue = blueTeam.objectives.baron.kills;
  let baronRed = redTeam.objectives.baron.kills;

  let blueDragons = objectives.filter(a => a.killerTeamId == 100 && a.monsterType == "DRAGON" && a.monsterSubType != "ELDER_DRAGON");
  let redDragons = objectives.filter(a => a.killerTeamId == 200 && a.monsterType == "DRAGON" && a.monsterSubType != "ELDER_DRAGON")

  let elderDragonsBlue = objectives.filter(a => a.killerTeamId == 100 && a.monsterType == "DRAGON" && a.monsterSubType == "ELDER_DRAGON");
  let elderDragonRed = objectives.filter(a => a.killerTeamId == 200 && a.monsterType == "DRAGON" && a.monsterSubType == "ELDER_DRAGON");

  console.log(baronBlue);

  return <div className="league-team-stats">
    <TeamStatsListElement index={1} label={"KDA"} valueBlue={`${teamKillsBlue} / ${teamDeathsBlue} / ${teamAssistsBlue}`} valueRed={`${teamKillsRed} / ${teamDeathsRed} / ${teamAssistsRed}`}/>
    <TeamStatsListElement index={2} label={"GOLD"} valueBlue={formatGold(goldBlue) + "k"} valueRed={formatGold(goldRed) + "k"}/>
    <TeamStatsListElement index={3} label={"TOWERS"} valueBlue={blueTeam.objectives.tower.kills} valueRed={redTeam.objectives.tower.kills}/>

    <TeamStatsListElement index={4} label={"VOID GRUBS"} valueBlue={<>{_.times(voidGrubsBlue).map(a => <img src={Grubs} className="grubs" />)}</>} valueRed={<>{_.times(voidGrubsRed).map(a => <img src={Grubs} className="grubs" />)}</>} extra={"void-grubs"}/>
    <TeamStatsListElement index={5} label={"HERALD"} valueBlue={<>{_.times(heraldBlue).map(a => <img src={Herald} className="herald" />)}</>} valueRed={<>{_.times(heraldRed).map(a => <img src={Herald} className="herald" />)}</>}/>
    <TeamStatsListElement index={6} label={"DRAKES"} valueBlue={<>{blueDragons.map( a => {
      return <img className="dragon" src={dragons[a.monsterSubType]} />
    })}</>} valueRed={<>{redDragons.map( a => {
      return <img className="dragon" src={dragons[a.monsterSubType]} />
    })}</>}/>
    <TeamStatsListElement index={7} label={"BARONS"} valueBlue={<>{_.times(baronBlue).map(a => <img src={Baron} className="baron" />)}</>} valueRed={<>{_.times(baronRed).map(a => <img src={Baron} className="baron" />)}</>}/>
    <TeamStatsListElement index={8} label={"ELDERS"} valueBlue={<>{elderDragonsBlue.map( a => {
      return <img className="elderdragon" src={dragons[a.monsterSubType]} />
    })}</>} valueRed={<>{elderDragonRed.map( a => {
      return <img className="elderdragon" src={dragons[a.monsterSubType]} />
    })}</>}/>
    <TeamStatsListElement index={9} label={"BANS"} valueBlue={<>
      {blueTeam.bans.map(a => {
        if(a.championId == -1) return;
        return <img className="ban" src={`https://cdn.communitydragon.org/latest/champion/${a.championId}/tile`} />
      })}
    </>} valueRed={<>
      {redTeam.bans.map(a => {
        if(a.championId == -1) return;
        return <img className="ban" src={`https://cdn.communitydragon.org/latest/champion/${a.championId}/tile`} />
      })}
    </>} extra={"last"}/>
  </div>
}

const TeamStatsListElement = ({label, valueBlue, valueRed, index, extra}) => {
  return <div className={"team-list-element " + "index" + index + " " + extra} style={{"--index": index - 1}}>
    <div className="blue-side">{valueBlue}</div>
    <div className="label">{label}</div>
    <div className="red-side">{valueRed}</div>
  </div>
}

const formatGold = (gold) => {
  return ((gold * 1.0) / 1000).toFixed(2);
};


const formatDamage = (dmg) => {
  return ((dmg * 1.0) / 1000).toFixed(2);
};

const formatTime = (time, live = "LIVE") => {
  if (time == 0) return live;
  if (time == -1) return live;
  if (time <= 0) return live;

  var minutes = Math.floor(time / 60);
  var seconds = Math.floor(time % 60);

  var displayText = "";

  if (minutes > 0) {
    displayText =
      (minutes < 10 ? "0" : "") +
      minutes +
      ":" +
      (seconds < 10 ? "0" : "") +
      seconds;
  } else {
    displayText = "00:" + (seconds < 10 ? "0" : "") + seconds;
  }

  if (time === -1) {
    displayText = "";
  }
  return displayText;
};


export default Endgame;