import { useState, useEffect, useContext } from "react";
import "./Tft.scss"
import Select from 'react-select';

import { DarkThemeStyles } from "../utils/DarkThemeStyles";
import { TftSets, useTftPages } from "../utils/TftPages";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { MatchTags } from "../utils/Tags";
import classNames from "classnames";
import moment from "moment";
import _ from "lodash";
import { set } from "react-hook-form";
import getTftIconUrl from "../utils/TftIconUrl";
import { DataContext } from "../DataWrapper/DataWrapper";
import { ReactComponent as Star } from "../../assets/images/black-star-icon.svg";
import { Tooltip } from 'react-tooltip';
import { usePlayers } from "../utils/Players";

const availableSites = TftSets;

const TftMatches = () => {

  const {site, sites, setSite} = useTftPages();
  const {players} = usePlayers("tft");

  const [matches, setMatches] = useState([]);
  const [page, setPage] = useState(1);
  const [countPerPage, setCountPerPage] = useState(50)

  let siteOptions = sites.map(a => {
    return {
      label: a.title,
      value: a.id
    }
  })

  const [tags, setTags] = useState([]);
  

  const changeSet = (e) => {
    setPage(1);
    setSite(sites.find(a => a.id == e.value));
  }

  useEffect(() => {
    if(!site) return;
    axios.get(`/backend/tft/matches/list/${site.id}/${page}/${countPerPage}`).then(res => {
      setMatches(res.data);
    })
  }, [site?.id, page])

  const prevPage = () => {
    let n = page - 1;
    if(n <= 0) n = 1;
    
    setPage(n);
  }

  const nextPage = () => {
    let n = page + 1;
    if(matches.length < countPerPage) n = page;
    
    setPage(n)
  }

  const jumpTo = () => {

  }

  return (
    <div className="container-fluid">
      <div className="row my-2 justify-content-end">
        <div className="col-12 col-lg-9 text-end">
          <Link to={"/tft"} className="btn btn-primary btn-sm me-2">
            Players
          </Link>
          <Link to={"/tft/stream"} className="btn btn-primary btn-sm">
            Stream
          </Link>
        </div>
        <div className="col-12 col-lg-3">
          <Select
            defaultValue={page}
            onChange={changeSet}
            options={siteOptions}
            value={siteOptions.find(a => a.value == site.id)}
            styles={
              DarkThemeStyles
            }
          />
        </div>
      </div>
      <div className="row mt-3">
        <div classNames="col-12 d-flex align-items-start justify-content-end">
          <nav className="d-flex align-items-center justify-content-end">
            <ul className="pagination">
              <li className={classNames("page-item", page > 1 ? "" : "disabled")}>
                <a className="page-link" onClick={prevPage}><i className="fas fa-arrow-left"></i></a>
              </li>
              {_.times(2).reverse().map(a => {
                let index = page - a - 1;

                if(index <= 0) return;
                return <li className="page-item"><a class="page-link" href="#">{page - a - 1}</a></li>
              })}
              <li className="page-item active" aria-current="page"><a className="page-link" href="#">{page}</a></li>
              <li className={classNames("page-item", matches.length < countPerPage ? "disabled" : "")} onClick={nextPage}>
                <a className="page-link" href="#"><i className="fas fa-arrow-right"></i></a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div className="row">
          <div className="col-12">
            <MatchesList matches={matches} players={players} />
          </div>
      </div>
    </div>
  );
}

const MatchesList = ({matches, players, site}) => {

  const setMatchActive = (match) => {
    axios.post("/backend/tft/matches/end/" + match.id);
  }

  const setMatchActiveSlot = (match, slot) => {
    axios.post("/backend/tft/matches/end/" + match.id + "/" + slot);
  }

  return <div className="table-responsive"><table className="table table-bordered table-striped">
    <thead>
      <tr>
        <th style={{width: "150px"}}>Match-ID</th>
        <th style={{width: "200px"}}>Timestamps</th>
        <th style={{width: "100px"}}>Version</th>
        <th>Players</th>
        <th>Tags</th>
        <th style={{width: "150px"}}></th>
        <th style={{width: "150px"}}></th>
        <th style={{width: "200px"}}></th>
      </tr>
    </thead>
    <tbody>
      {matches.length == 0 && <tr><td colSpan={6}>No Matches found</td></tr>}
      {matches.map(a => {
        let datetime = moment(a.match?.info?.game_datetime || Number(a.match?.json?.game_datetime));

        return <tr>
          <td>{a.matchId}</td>
          <td>{datetime.format("DD.MM.YYYY HH:mm")}</td>
          <td>{a.version}</td>
          <td>{a.puuids.map(c=> {
            let player = players.find(b => b.tftTournamentPuuid == c || b.tftPuuid == c)
            return player ? <span className="badge bg-primary me-2">{player.nickname}</span> : undefined
          })}</td>
          <td>{a.tags.map(a => <span className="badge bg-primary me-2">{a}</span>)}</td>
          <td>
            <Link to={"/tft/matches/" + a.id} target="_NEW" className="btn btn-primary d-block w-100 btn-sm">Show</Link>
          </td>
          <td>
            <span className="btn btn-warning d-block w-100 btn-sm" onClick={() => {
              setMatchActive(a);
            }}>Set active</span>
          </td>
          <td>
            <div class="btn-group" role="group" aria-label="First group">
              {_.times(8).map(b => {
                return <span className="btn btn-info" onClick={() => {
                  setMatchActiveSlot(a, b+1);
                }}>{b + 1}</span>
              })}
            </div>
          </td>
        </tr>
      })}
    </tbody>
  </table></div>
}

export default TftMatches;