import { useContext } from "react";
import getTftIconUrl from "../utils/TftIconUrl";
import { DataContext } from "../DataWrapper/DataWrapper";

const TftTestImages = () => {
  
  const {tftData} = useContext(DataContext);
  const setData = tftData["set_10"];
  const championStats = setData.champions;

  let champions = Object.keys(championStats.data);
  
  return <div className="row mt-2">
    <div className="col-12">
      <div className="row">
        {champions.map(a => <div className="col-1">
          <img src={getTftIconUrl(a, championStats.data)} className="w-100"/>
          <p className="mb-0 text-wrap">{a}</p>
        </div>)}
      </div>
    </div>
  </div>
}
export default TftTestImages;