import classNames from "classnames";

const StatHighlight = ({
  imageUrl,
  name,
  label,
  stat1,
  stat1Label,
  stat2,
  stat2Label,
  stat3,
  stat3Label,
  extraImageClass,
  championCost
}) => {
  return (
    <li className="list-group-item stat-highlight" key={name} data-name={name}>
      <div className={classNames("image-wrapper", extraImageClass)}>
        <img src={imageUrl} className={"image"} alt={label || name} />
        {championCost && <span className="cost">{championCost}</span>}
      </div>

      <span className="label">{label || name}</span>
      {stat1 && <span className="stat">{stat1}<span className="stat-title">{stat1Label}</span></span>}
      {stat2 && <span className="stat">{stat2}<span className="stat-title">{stat2Label}</span></span>}
      {stat3 && <span className="stat">{stat3}<span className="stat-title">{stat3Label}</span></span>}
    </li>
  )
}

export default StatHighlight;