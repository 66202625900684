import { useState, useEffect, useContext } from "react";
import "./Tft.scss"

import { DarkThemeStyles } from "../utils/DarkThemeStyles";
import { TftSets, useTftPages, formatSitesForSelect, TftPageSelect } from "../utils/TftPages";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

import TftSet11 from "./sets/Set11/TftSet11";
import TftA1eSports from "./sets/A1eSports/TftA1eSports";

import { DataContext } from "../DataWrapper/DataWrapper";
import { UserContext } from "../User/User";
import { usePlayers } from "../utils/Players";
import _ from "lodash";
import classNames from "classnames";

const availableSites = TftSets;

const Tft = () => {

  const { sites, setSite, site} = useTftPages();
  const { players } = usePlayers("tft", site?.id);
  const {playerId} = useParams();

  const {publicAccess} = useContext(UserContext);
  const {tftData} = useContext(DataContext);
 
  const pages = formatSitesForSelect(sites);
  const player = players.find(p => p.id == playerId);  

  return (
    <div className="container-fluid">
      <div className="row my-2">
        <div className="col-12 text-end">
          <Link to={"/tft/matches"} className="btn btn-primary btn-sm me-2">
            Matches
          </Link>
          {!publicAccess && <Link to={"/tft/stream"} className="btn btn-primary btn-sm">
            Stream
          </Link>}
        </div>
      </div>
      <div className="row my-2">
        <div className="col-12 col-lg-3">
          <TftPageSelect site={site} sites={sites} setSite={setSite} />
          <TftPlayerSelect players={players} player={player} page={site}/>
        </div>
        <div className="col-12 col-lg-9">
          <TftContent page={site} player={player} players={players} />
        </div>
      </div>
    </div>
  );
}

const TftPlayerSelect = ({players, player, page}) => {

  const [search, setSearch] = useState("");

  let availablePlayers = search.length > 0 ? players.filter(a => ((a.nickname || "").toLowerCase().includes(search.toLowerCase()))) : players
  availablePlayers = _.sortBy(availablePlayers, a => a.active == undefined ? 0 : (a.active ? 0 : 1));


  return <div className="card mt-2 mb-3">
    <div className="card-header">
      PLAYERS
    </div>
    <ul className="list-group list-group-flush">
      <i className="list-group-item p-0">
        <div className="input-group">
          <input className="form-control search-player" type="text" placeholder="Search for Name" value={search} onChange={a =>  {
            setSearch(a.target.value);
          }} />
          {search && <span className="clear-search" onClick={() => {
            setSearch("");
          }}>CLEAR</span>}
        </div>
      </i>
      {availablePlayers.length == 0 && <li className="list-group-item">No Players found with '{search}'</li>}
      {availablePlayers.map((p, i) => {
        //console.log(p);
        let active = p.active == undefined ? true : p.active
        return (<Link className={"list-group-item list-group-item-action " + (player?.id == p.id ? "active" : "")} key={"tft_player_" + p.id} to={`/tft/p/${p?.id}`}>
          {p.nickname}
          <span className={classNames("badge", "ms-2", active ? "bg-success" : "bg-danger")}>{active ? "active" : "inactive"}</span>
          {p.statsPresent && <span className="badge bg-info ms-2">Stats</span>}
        </Link>)
      })}
    </ul>
  </div>
}

const sets = ["set_11", "set_12", "a1_2024_set11_1", "a1_2024_set12_1"];

const TftContent = ({page, player}) => {
  if(!page) return;
  if(!player) return;

  if(sets.includes(page.identifier)) {
    return <TftSet11 set={page} player={player} site={page} />
  }
}

export default Tft;