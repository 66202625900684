import { useContext, useState } from "react";
import { DataContext } from "../../../DataWrapper/DataWrapper";
import _ from "lodash";
import getTftIconUrl from "../../../utils/TftIconUrl";
import { ObjectToArray } from "../../../utils/ObjectToArray";
import StatHighlight from "./StatHighlight";
import classNames from "classnames";

const TftChampionStats = ({player, stats, site}) => {

  const {tftData} = useContext(DataContext);

  const setData = tftData[`set_${site.tftSet}`];
  const championStats = setData.champions;
  const [showAll, setShowAll] = useState(false);

  let unsortedChampionStats = ObjectToArray(stats.unitStats  );
  let sortedChampionStats = _.sortBy(unsortedChampionStats, a => a.obj.games).reverse();
  sortedChampionStats = showAll ? sortedChampionStats : sortedChampionStats.slice(0, 5);

  return <div className="card mt-2">
  <div className="card-header position-relative">
    Champion Stats
    <span className="show-all" onClick={() => {
      setShowAll(prev => !prev);
    }}>{showAll ? "Show 5" : "Show all"}</span>
  </div>
  <div className="card-body p-1">
    <ul className="list-group">
      {sortedChampionStats.map(a => {
        let imageUrl = getTftIconUrl(a.name, championStats.data, site.leagueVersion);
        let label = championStats.data[a.name]?.name || a.name;

        console.log(`ChampionsData`, championStats.data[a.name], championStats.data, "a", a.name);

        let averagePosition = 0;
        let sumPositions = 0;
        for(let i = 1; i < 9; i++) {
          sumPositions += a.obj.positions[i] * i
        }
        if(stats.games > 0) averagePosition = (sumPositions / a.obj.games); 

        return <StatHighlight
          name={a.name}
          label={label}
          imageUrl={imageUrl}
          extraImageClass={classNames("tft-champion", "tier" + championStats.data[a.name]?.tier)}
          stat1Label={"Games"}
          stat2Label={"Top-4 %"}
          stat3Label={"Avg. Pos."}
          stat1={a.obj.games}
          stat2={(a.obj.wins / a.obj.games * 100).toFixed(1) + " %"}
          stat3={"#" + averagePosition.toFixed(2)}
          championCost={championStats.data[a.name]?.tier}
          key={a.name}
        />
      })}
    </ul>
  </div>
</div>
}



export default TftChampionStats;