import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import { useState } from "react";
import PageWrapper from "./components/PageWrapper";
import Home from "./components/Home/Home";
import Tft from "./components/Tft/Tft";
import { DataWrapper } from "./components/DataWrapper/DataWrapper";
import { ErrorBoundary } from "react-error-boundary";
import Players from "./components/Players/Players";
import TftTestImages from "./components/Tft/TftTestImages";
import StreamTft from "./components/Stream/Tft/StreamTft";
import LCUClient from "./components/LCUClient/LCUClient";
import TftMatches from "./components/Tft/TftMatches";
import TftMatch from "./components/Tft/TftMatch";
import Control from "./components/Control/Control";
import {useJwtToken, usePublicAccess, UserContext} from "./components/User/User";
import Login from "./components/Login/Login";
import Endgame from "./components/Overlays/tft/Endgame";
import LoLEndgame from "./components/Overlays/league/Endgame";
import LeagueOfLegends from "./components/LeagueOfLegends/LeagueOfLegends";
import StreamLeagueOfLegends from "./components/Stream/LeagueOfLegends/StreamLeagueOfLegends";

const router = createBrowserRouter([
  {
    path: "/",
    element: <PageWrapper><Home /></PageWrapper>,
    errorElement: <ErrorBoundary>
      <div className="alert alert-danger m-2">
        <h4 class="alert-heading">404</h4>
        <hr />
        <p className="mb-0">Site you requested not found</p>
      </div>
    </ErrorBoundary>
  },
  // OFFLINE
  {
    path: "/lcu",
    element: <PageWrapper><LCUClient /></PageWrapper>,
  },
  // TFT
  {
    path: "/tft",
    element: <PageWrapper><Tft /></PageWrapper>,
  },
  {
    path: "/tft/p/:playerId",
    element: <PageWrapper><Tft /></PageWrapper>,
  },{
    path: "/tft/images",
    element: <PageWrapper><TftTestImages /></PageWrapper>
  },{
    path: "/tft/stream",
    element: <PageWrapper><StreamTft /></PageWrapper>
  },{
    path: "/tft/matches",
    element: <PageWrapper><TftMatches /></PageWrapper> 
  },{
    path: "/tft/matches/:matchId",
    element: <PageWrapper><TftMatch /></PageWrapper> 
  },
  // PLAYERS
  {
    path: "/players",
    element: <PageWrapper><Players /></PageWrapper>
  },
  {
    path: "/players/:playerId",
    element: <PageWrapper><Players /></PageWrapper>
  },
  // LEAGUE
  {
    path: "/league",
    element: <PageWrapper><LeagueOfLegends /></PageWrapper>
  },
  {
    path: "/league/stream",
    element: <PageWrapper><StreamLeagueOfLegends /></PageWrapper>
  },
  // VALORANT
  {
    path: "/valorant",
    element: <PageWrapper><h1>Valorant</h1></PageWrapper>
  },
  // CONTROL
  {
    path: "/control",
    element: <PageWrapper><Control /></PageWrapper>
  },
  // OVERLAYS
  {
    path: "/tft/overlay/endgame",
    element: <Endgame />
  },{
    path: "/league/overlay/endgame",
    element: <LoLEndgame />
  }
]);

function App() {
  return (
    <DataWrapper>
      <RouterProvider router={router} />
    </DataWrapper>
  );
}

export default App;
