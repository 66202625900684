import axios from "axios";
import { useEffect, useState } from "react";

const Home = () => {
  
  return (
    <div className="container-fluid">
      <div className="row mt-2">
        <div className="col-12">
          <div className="alert alert-danger">
            This is not finished please take stats with caution and report errors to the developer
          </div>
          <h2>A1eSports Stats Tool</h2>
          <p>
            Unser Tool bietet eine umfassende Sammlung von Statistiken für die Spiele der A1eSports League sowie für Spieler außerhalb der Liga. Egal ob du nach Daten zu Spielen, Teams oder einzelnen Spielern suchst, unser Tool ermöglicht es dir, Informationen zu finden und zu filtern, die genau deinen Bedürfnissen entsprechen.
          </p>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-12 col-lg-6">
          <Sizes />
        </div>
      </div>
    </div>
  );
}

const Sizes = () => {
  const [sizes, setSizes] = useState(undefined);

  useEffect(() => {
    axios.get('/backend/sizes').then(response => {
        setSizes(response.data);
    }).catch(error => {
        console.log(error);
    });
  }, []);

  if(!sizes) return;

  let keys = [
    ["TFT Matches", "tftMatches"],
    ["Cached Riot Data", "dataDragon"],
    ["Database", "databases"],
  ]

  return <div className="row">
    {keys.map(a => <div className="col">
      <div className="card">
        <div className="card-header">{a[0]}</div>
        <div className="card-body">
          <h4 className="mb-0 text-center" style={{fontWeight: "bold"}}>{humanFileSize(sizes[a[1]], true)}</h4>
        </div>
      </div>
    </div>)}
  </div>
}

function humanFileSize(bytes, si=false, dp=1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si 
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10**dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


  return bytes.toFixed(dp) + ' ' + units[u];
}

export default Home;