import { useEffect, useState } from "react";
import TftGeneralStats from "./TftGeneralStats";
import axios from "axios";
import TftChampionStats from "./TftChampionStats";
import TftAugmentsStats from "./TftAugmentsStats";
import TftTraitsStats from "./TftTraitStats";
import TftItemsStats from "./TftItemsStats";
import TftMatches from "./TftMatches";

const TftPlayer = ({ player, site }) => {

  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState(undefined);
  const url = `/backend/tft/player/${site.id}/${player.id}`;

  useEffect(() => {
    setLoading(true);
    setStats(undefined);
    axios.get(url).then(res => {
      setStats(res.data);
    }).catch(err => {
      alert(err);
    }).finally(() => {
      setLoading(false);
    })
  }, [player])

  console.log(`Stats`, url, stats);

  if(loading) {
    return (<div className="alert alert-warning">Stats werden geladen...</div>)
  }

  if(!stats) return <div className="alert alert-danger">
    No Stats found for this player.
  </div>

  return <div className="row">
    <div className="col-12">

    </div>
    <div className="col-12 col-lg-6">
      <TftGeneralStats player={player} stats={stats} site={site}/>
      <TftChampionStats player={player} stats={stats} site={site}/>
      { false && <TftMatches player={player} />}
    </div>
    <div className="col-12 col-lg-6">
      { true && <TftAugmentsStats player={player} stats={stats} site={site} /> }
      { true && <TftTraitsStats player={player} stats={stats} site={site} /> }
      { true && <TftItemsStats player={player} stats={stats} site={site} /> }
    </div>
  </div>
}
export default TftPlayer;