import { useState, useEffect, useContext } from "react";
import "./Tft.scss"
import Select from 'react-select';

import { DarkThemeStyles } from "../utils/DarkThemeStyles";
import { TftSets } from "../utils/TftPages";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { MatchTags } from "../utils/Tags";
import classNames from "classnames";
import moment from "moment";
import _ from "lodash";
import { set } from "react-hook-form";
import getTftIconUrl from "../utils/TftIconUrl";
import { DataContext } from "../DataWrapper/DataWrapper";
import { ReactComponent as Star } from "../../assets/images/black-star-icon.svg";
import { Tooltip } from 'react-tooltip';
import { usePlayers } from "../utils/Players";

const TftMatch = () => {

  const {matchId} = useParams();

  const {players} = usePlayers("tft");

  const [result, setResult] = useState(undefined)

  useEffect(() => {
    setResult(undefined);
    axios.get(`/backend/tft/matches/fetch/${matchId}`).then(res => {
      console.log(res.data);
      setResult(res.data);
    }).catch(err => {
      alert("Something went wrong");
    })
  }, [matchId])

  let match = result?.match;
  let page = result?.page;

  return <div className="container-fluid">
    <div className="row my-2 justify-content-end">
        <div className="col-12 text-end">
          <Link to={"/tft"} className="btn btn-primary btn-sm me-2">
            Players
          </Link>
          <Link to={"/tft"} className="btn btn-primary btn-sm me-2">
            Matches
          </Link>
          <Link to={"/tft/stream"} className="btn btn-primary btn-sm">
            Stream
          </Link>
        </div>
      </div>
    <div className="row mb-2">
      <div className="col-12">
        {!result && <div className="alert alert-danger">No Match found with this Id</div>}
        {match && <PreviewMatch matchObj={match} players={players} page={page} />}
      </div>
    </div>
  </div>;
}

const PreviewMatch = ({matchObj, players, page}) => {
  let match = matchObj.match;
  let dateTime = moment(Number(match.info?.game_datetime || match.json?.game_datetime));
  let gameId = matchObj.matchId;

  const {tftData} = useContext(DataContext);
  const setData = tftData["set_" + matchObj.tftSet];

  if(!setData) return;

  const championStats = setData.champions;
  const itemData = setData.items;
  const augmentsStats = setData.augments;
  const content = setData.content;
  const traitStats = setData.traits;

  let participants = match.json?.participants || match.info?.participants;

  let sortedParticipants = _.sortBy(participants, a => {
    return a.placement;
  })

  let infos = [
    ["Game-ID", gameId],
    ["Set", matchObj.tftSet],
    ["Date", dateTime.format("DD.MM.YYYY HH:mm")],
    ["Tags", matchObj.tags.map(a => <span className="badge bg-primary me-2">{a}</span>)]
  ]

  return <div className="row mb-3">
    {infos.map(a => {return(<div className="col-6 col-lg-3 col-xl-2">
      <p className="game-info">{a[0]}</p>
      <p className="game-info-value">{a[1]}</p>
    </div>)})}
    <div className="col-12"><hr /></div>
    <div className="col-12"><div className="row"  id="tft-match">
      {sortedParticipants.map((p, i) => { 
        let player = players.find(b => b.tftTournamentPuuid == p.puuid || b.tftPuuid == p.puuid)
        
        console.log(player, p);

        let sortedUnits = _.sortBy(p.units, a => a.tier).reverse()

        let stats = [
          [p.level, "LEVEL"],
          [p.total_damage_to_players, "DAMAGE"],
          [p.players_eliminated, "KILLS"]
        ]

        return <div className="d-flex">
          <div className="tft-match-player">
            <div className={classNames("tft-match-placement", `p${p.placement}`)}>{p.placement}</div>
            <div className={classNames("tft-match-name", player ? "" : "puuid")}>
              {player ? player.nickname : `Player ${i+1}`}
            </div>
            <div className="tft-match-units">
              {sortedUnits.map(unit => {
                let imageUrl = getTftIconUrl(unit.character_id, championStats.data, page.leagueVersion);
                let label = championStats.data[unit.character_id]?.name || unit.character_id;

                return <div className={classNames(classNames("tft-match-champion", "rarity" + unit.rarity, (unit.itemNames.length == 0 ? "remove-items" : "")))}>
                  <span className="stars">{_.times(unit.tier).map(a => <Star className={"star lvl" + unit.tier}/>)}</span>
                  <img src={imageUrl} className={"img-fluid w-100"} />
                  <span className={"items"}>
                    {unit.itemNames.map(itemId => {
                      let item = itemData.data[itemId];
                      if(!item) return;
                      let placeholder = "https://ddragon.leagueoflegends.com/cdn/" + page.leagueVersion + ".1/img/tft-item/@@name@@";
                      let imageUrl = placeholder.replace("@@name@@", item?.image?.full);

                      return <img class="item" src={imageUrl}/>
                    })}
                  </span>
                </div>
              })}
            </div>
            <div className="tft-match-traits">
              {_.orderBy(p.traits, b => b.tier_total - b.tier_current).map(a => {
                let placeholder = "https://ddragon.leagueoflegends.com/cdn/" + page.leagueVersion + ".1/img/tft-trait/@@name@@";
                let imageUrl = placeholder.replace("@@name@@", traitStats.data[a.name]?.image?.full);
                let label = traitStats.data[a.name]?.name || a.name;

                let traitTier = "none"

                if (a.tier_total == 1) {
                  if(a.tier_current == 1) traitTier = "gold";
                }

                if (a.tier_total == 2) {
                  if(a.tier_current == 1) traitTier = "silber";
                  if(a.tier_current == 2) traitTier = "gold";
                }

                if (a.tier_total == 3) {
                  if(a.tier_current == 1) traitTier = "bronze";
                  if(a.tier_current == 2) traitTier = "silber";
                  if(a.tier_current == 3) traitTier = "gold";
                }

                if (a.tier_total == 4) {
                  if(a.tier_current == 1) traitTier = "bronze";
                  if(a.tier_current == 2) traitTier = "silber";
                  if(a.tier_current == 3) traitTier = "gold";
                  if(a.tier_current == 4) traitTier = "chromatic";
                }


                if(a.tier_current == 0) return;
                //console.log(label, a, traitTier, a.tier_current, a.tier_total)

                return <span className={classNames("trait", traitTier)}>
                  <img src={imageUrl} className="trait-img" />
                  {label}
                  <span className="value">{a.num_units}</span>
                </span>
              })}
            </div>
            <div className="tft-match-space"></div>
            <div className="tft-match-augments">
              {p.augments.map(augment => {
                let placeholder = "https://ddragon.leagueoflegends.com/cdn/" + page.leagueVersion + ".1/img/tft-augment/@@name@@";
                let imageUrl = placeholder.replace("@@name@@", augmentsStats.data[augment]?.image?.full);
                let contentItem = (content?.items || []).find(a => a.apiName === augment);
                
                let name = contentItem?.name;
                let description = contentItem?.desc;

                for(let [key, value] of Object.entries((contentItem?.effects || {}))) {
                  description = description.replace(`@${key}@`, value);
                }

                return <>
                  <div 
                    className="augment"
                    data-tooltip-id={p.puuid + "_aug_" + augment}
                    data-tooltip-name={name}
                    data-tooltip-content={description}
                    data-tooltip-effects={JSON.stringify(contentItem?.effects)}
                  >
                    <img src={imageUrl} />
                  </div>
                  <Tooltip
                    id={p.puuid + "_aug_" + augment}
                    render={({ content, activeAnchor }) => {
                      return <span>
                        <h6 style={{maxWidth: "300px"} }>{activeAnchor?.getAttribute('data-tooltip-name')}</h6>
                        <p style={{maxWidth: "300px"}} className="mb-0" dangerouslySetInnerHTML={{__html: content}}></p>
                        <hr />
                        {activeAnchor?.getAttribute('data-tooltip-effects')}
                      </span>
                    }}
                  />
                </>
              })}
            </div>
            <div className="tft-match-stats">
              {stats.map(a => (<div className="tft-stat">
                {a[0]}  
                <div className="tft-stat-title">{a[1]}</div>
              </div>))}
            </div>
          </div>
        </div>
        }
      )}
    </div></div>
  </div>
}

export default TftMatch;