import { useForm, FormProvider } from "react-hook-form";
import TextInput from "../Forms/TextInput/TextInput";
import { useState, useEffect, useContext } from "react";
import { CreatableInputOnlyWithController } from "../Forms/CreatableInput/CreatableInput";
import axios from "axios";
import Checkbox from "../Forms/Checkbox/Checkbox";
import { UserContext } from "../User/User";

const EditPlayer = ({player, callback}) => {

  const methods = useForm();
  const [loading, setLoading] = useState(false);
  const { token } = useContext(UserContext)

  const onSubmit = (data) => {
    setLoading(true);

    console.log("token", token)

    axios.patch("/backend/players/" + player.id, data, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      callback(new Date());
      setLoading(false);
    }).catch(err => {
      alert(err.toString());
      console.log(err);
      setLoading(false);
    })
  }

  useEffect(() => {
    methods.reset(player);
  }, [player])

  if(loading) return <div className="alert alert-warning">Is saving...</div>

  return (<div className="card">
    <div className="card-header">{player.nickname}</div>
    <div className="card-body">
      <FormProvider {...methods}>
        <form className="form" onSubmit={methods.handleSubmit(onSubmit)}>
          <TextInput name="flaveId" readonly={true} label="FLAVE-ID" />
          <TextInput name="nickname" required={true} label="Nickname" placeholder="zb. XD0M3" />
          <hr />
          <h4>Teamfight Tactics</h4>
          <TextInput name="tftPuuid" required={false} label="TFT Puuid" placeholder="TFT Puuid (riot api)" />
          <TextInput name="tftTournamentPuuid" required={false} label="TFT Puuid" placeholder="TFT (TR) Puuid (riot api)" />
          <Checkbox name="tft" label="Is Playing?"/>
          <hr />
          <h4>Valorant</h4>
          <TextInput name="valorantPuuid" required={false} label="Valorant Puuid" placeholder="Valorant Puuid (riot api)" />
          <Checkbox name="valorant" label="Is Playing?"/>
          <hr />
          <h4>League of Legends</h4>
          <TextInput name="leaguOfLegendsPuuid" required={false} label="LoL Puuid" placeholder="LoL Puuid (riot api)" />
          <Checkbox name="leagueOfLegends" label="Is Playing?"/>
          <hr />
          <Checkbox name="active" label="Active"/>
          <hr />
          <button type="submit" className="btn btn-primary">Edit</button>
        </form>
      </FormProvider>
    </div>
  </div>)
}

export default EditPlayer;