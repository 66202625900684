import { TftSets } from "../utils/TftPages";
import { useContext, useState } from "react";
import Select from 'react-select';
import { DarkThemeStyles } from "../utils/DarkThemeStyles";
import axios from "axios";
import { UserContext } from "../User/User";

const Control = () => {

  const { publicAccess } = useContext(UserContext)
  if(publicAccess) {
    return <div className="alert alert-danger m-2">No access to this page</div>
  }

  return <div className="container-fluid">
    <div className="row mt-2">
      <div className="col-12 col-xl-6">
        <TftPlayerLiveStats />
        <TftPlayerTrStats />
      </div>
    </div>
  </div>;
}

const TftPlayerLiveStats = () => {

  const sets = TftSets.filter(a => !a.a1esports);
  const [set, setSet] = useState(sets.find(a => a.default));
  const [response, setResponse] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const { token } = useContext(UserContext)


  const changeSet = (a) => {
    setSet(a);
  }

  const startFetching = () => {
    if(loading) return;
    if(!set) return;
    
    setLoading(true);

    axios.post("/backend/control/fetch/" + set.setNumber, {}, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      setResponse(res.data);
      setLoading(false);
    }).catch(err => {
      setResponse(
        {
          error: true,
          message: "Bad request, something missing or invalid"
        }
      )
      setLoading(false);
    })
  }

  return <div className="card mb-3">
    <div className="card-header">TftLiveStats</div>
    <div className="card-body">
      <div className="col-12">
        <Select
            defaultValue={set}
            onChange={changeSet}
            options={sets}
            value={sets.find(a => a.value === set.value)}
            styles={
              DarkThemeStyles
            }
          />
        <hr />
        <span 
          className={"btn btn-primary w-100 d-block btn-sm " + ((set)  ? "" : "disabled") + (loading ? " disabled" : "")} 
          disabled={(!set || loading)}
          onClick={startFetching}
        >Fetch Data</span> 
        {response && <Response response={response} />}
      </div>
    </div>
  </div>
}

const TftPlayerTrStats = () => {

  const sets = TftSets.filter(a => a.a1esports);
  const [set, setSet] = useState(sets[0]);
  const [response, setResponse] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const { token } = useContext(UserContext)

  const changeSet = (a) => {
    setSet(a);
  }

  const startFetching = () => {
    if(loading) return;
    if(!set) return;
    
    setLoading(true);

    axios.post("/backend/control/fetchTr/" + set.value,{}, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      setResponse(res.data);
      setLoading(false);
    }).catch(err => {
      setResponse(
        {
          error: true,
          message: "Bad request, something missing or invalid"
        }
      )
      setLoading(false);
    })
  }

  return <div className="card">
    <div className="card-header">TftTrStats</div>
    <div className="card-body">
      <div className="col-12">
        <Select
            defaultValue={set}
            onChange={changeSet}
            options={sets}
            value={sets.find(a => a.value === set.value)}
            styles={
              DarkThemeStyles
            }
          />
        <hr />
        <span 
          className={"btn btn-primary w-100 d-block btn-sm " + ((set)  ? "" : "disabled") + (loading ? " disabled" : "")} 
          disabled={(!set || loading)}
          onClick={startFetching}
        >Fetch Data</span> 
        {response && <Response response={response} />}
      </div>
    </div>
  </div>
}

const Response = ({response}) => {
  if(response.running) {
    return <div className="alert alert-info mt-2 mb-0">Fetching is already running</div>
  }
  if(response.error) {
    return <div className="alert alert-danger mt-2 mb-0">{response.message}</div>
  }
  if(response.started) {
    return <div className="alert alert-success mt-2 mb-0">Fetching started</div>
  }

  return <div className="alert alert-warning mt-2 mb-0">Something went wrong</div>
}

export default Control;