import React, {useState, useEffect} from "react";

export const UserContext = React.createContext({ 
  token: null,
  setToken: () => {},
  publicAccess: false,
  setPublicAccess: () => {},
});

export function useJwtToken() {

  const getJwtToken = () => {
    return localStorage.getItem('a1esports_token');
  };

  const [token, setToken] = useState(getJwtToken());

  const saveJwtToken = token => {
    localStorage.setItem('a1esports_token', token);
    if(!token) {
      localStorage.removeItem("a1esports_token");
    }
    setToken(token);
  };

  return {
    setToken: saveJwtToken,
    token
  }
}

export function usePublicAccess() {

  const getPublicAccessToken = () => {
    return localStorage.getItem('a1esports_public') == "true";
  };

  const [isPublic, setIsPublic] = useState(getPublicAccessToken());

  const setPublicAccess = (bool) => {
    let value = bool ? "true" : "false";
    localStorage.setItem('a1esports_public', value);
    setIsPublic(bool);
  };

  return {
    setPublicAccess: setPublicAccess,
    publicAccess: isPublic
  }
}