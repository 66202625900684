import TftPlayer from "./TftPlayer";

const TftSet11 = ({player, site}) => {
  
  return <div className="row">
    <div className="col-12">
      <TftPlayer player={player} site={site}  />
    </div>
  </div>
}

export default TftSet11;