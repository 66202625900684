import React, {useState, useEffect} from "react";
import axios from "axios";

export const DataContext = React.createContext({ 
  tftData: {}
});

export const DataWrapper = ({...props}) => {

  const [tftData, setTftData] = useState(undefined);

  
  useEffect(() => {
    const fetchData = async () => {
      let res = await axios.get("/backend/tft/data");
      setTftData(res.data);
    }

    fetchData();
  }, [])

  if(!tftData) return;

  return <DataContext.Provider value={{
    tftData: tftData,
  }}>
    {tftData && props.children}
  </DataContext.Provider>
}