import Login from "./Login/Login";
import Navbar from "./Navbar/Navbar";
import { ErrorBoundary } from "react-error-boundary";
import { UserContext, useJwtToken, usePublicAccess } from "./User/User";

const PageWrapper = ({...props}) => {

  const { token, setToken } = useJwtToken();
  const { publicAccess, setPublicAccess } = usePublicAccess();

  console.log(token, (!token && !publicAccess), !token, !publicAccess)

  let showLoginForm = (!token && !publicAccess);

  if(showLoginForm) {
    return <Login setToken={setToken} setIsPublic={setPublicAccess} token={token} />;
  }

  return <UserContext.Provider value={{
    token: token,
    setToken: setToken,
    publicAccess: publicAccess,
    setPublicAccess: setPublicAccess,
  }}>
    <div className="page">
      <Navbar />
      <div className="page-content">
        <ErrorBoundary fallback={<Error />}>
          {props.children}
        </ErrorBoundary>
      </div>
    </div>
  </UserContext.Provider>
}

const Error = () => {
  return <div className="alert alert-danger m-2">
  <h4 class="alert-heading">Something went wrong</h4>
  <hr />
  <p className="mb-0">An Error occured, please try again or if this happens again please contact the admin.</p>
</div>
}

export default PageWrapper;