import { useForm, FormProvider } from "react-hook-form"
import TextInput from "../Forms/TextInput/TextInput"
import axios from "axios";
import { useContext } from "react";
import { UserContext } from "../User/User";

const NewPlayer = ({callback}) => {

  const methods = useForm()
  const { token } = useContext(UserContext);

  const onSubmit = (data) => {
    
    axios.post("/backend/players", data, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      methods.reset({
        flaveId: "",
        nickname: ""
      })
      callback(new Date());
    }).catch(err => {
      console.log(err);
    })
  }

  return <div className="card">
    <div className="card-header">
      New Player
    </div>
    <div className="card-body">
      <FormProvider {...methods}>
        <form className="form" onSubmit={methods.handleSubmit(onSubmit)}>
          <TextInput name={"flaveId"} label="FLAVE-ID" placeholder="zb. 1e1f945e-7cdb-4251-bcfb-e2ab27c41d06" />
          <TextInput name={"nickname"} label="Nickname" placeholder="zb. XD0M3" />
          <button type="submit" className="btn btn-primary">Submit</button>
        </form>
      </FormProvider>
    </div>
  </div>
}

export default NewPlayer;